.Card {
  height: 100%;
  display: grid;
  grid-template-rows: 40% 35% 25%;
  text-align: center;
  background-color: rgb(184, 37, 37);
}

.image > img {
  margin-top: 12%;
  width: 35%;
  border: 2px solid black;
  border-radius: 100%;
}

.intro {
  display: grid;
  grid-template-rows: auto 20% auto 30% auto 30% auto;
}

.headerInfo {
  display: grid;
  grid-template-rows: auto;
}

.links {
  display: grid;
  grid-template-columns: auto 20% 1% 20% 1% 20% auto;
}

.links img {
  margin-top: 25%;
  width: 35%;
  border-radius: 30%;
}

Button {
  border: 1px solid;
}

.cvDownload > a {
  width: 60%;
  max-width: 200px;
  min-width: 80px;
}

@media screen and (min-width: 1301px) {
  .headSpan {
    font-size: 60px;
  }

  .lowerSpan {
    font-size: 30px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1300px) {
  .headSpan {
    font-size: 50px;
  }

  .lowerSpan {
    font-size: 20px;
  }

  .cvDownload > a {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .Card {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: rgb(184, 37, 37);
  }

  .intro {
    display: flex;
    flex-direction: column
  }

  .image > img {
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .links img {
    margin-top: 20%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload a {
    margin-bottom: 5%;
    width: 40%;
    max-width: 200px;
    min-width: 80px;
  }
}

@media screen and (max-width:600px) and (max-height: 950px) {
  .image > img {
    margin-top: 5%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .links img {
    margin-top: 10%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: 5%;
  }
}

@media screen and (max-width:600px) and (max-height: 850px) {
  .image > img {
    margin-top: 1%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .links img {
    margin-top: 5%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: -2%;
  }

  .cvDownload a {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 40%;
    max-width: 250px;
    min-width: 80px;
  }
}

@media screen and (max-width:600px) and (max-height: 750px) {
  .image > img {
    margin-top: 1%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .links img {
    margin-top: 1%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: -3%;
  }
}

@media screen and (max-width:600px) and (max-height: 700px) {
  .image > img {
    margin-top: 1%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .links img {
    margin-top: 2%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: -4%;
  }
}

@media screen and (max-width:600px) and (max-height: 700px) {
  .image > img {
    margin-top: 1%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .intro {
    margin-top: -2.5%;
    scale: 0.80;
  }

  .links img {
    margin-top: 0%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: -6%;
  }
}

@media screen and (max-width:600px) and (max-height: 600px) {
  .image > img {
    margin-top: 1%;
    width: 15%;
    border: 2px solid black;
    border-radius: 100%;
  }

  .intro {
    margin-top: -2%;
    scale: 0.70;
  }

  .links img {
    margin-top: 0%;
    width: 25%;
    border-radius: 30%;
  }

  .cvDownload {
    scale: 0.8;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .headSpan {
    font-size: 30px;
  }

  .lowerSpan {
    font-size: 15px;
  }

  .cvDownload > a {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .headSpan {
    font-size: 20px;
  }

  .lowerSpan {
    font-size: 10px;
  }
  .cvDownload > a {
    font-size: 8px;
  }
}
