html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: black;
  font-style: oblique;
}
