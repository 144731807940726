.MobileLayout {
	height: 100%;
    display: grid;
    grid-template-rows: 25% auto 6%;
}

.Layout > footer {
    width: 100%;
}

.MobileLayout > main {
    height: 100%;
    grid-column: span auto;
    overflow: auto;
}

.MobileLayout > header {
    position: static;
}
