/* Desktop */
.About {
  height: 95%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.aboutScreen {
  display: grid;
  grid-template-columns: 2% 50% 2% auto 2%;
  height: 100%;
}

.mainText {
  display: grid;
  grid-template-rows: 8% 5% auto auto auto auto 10%;
  text-align: left;
}

.sideText {
  display: grid;
  grid-template-rows: 13% 15% 5% auto 10%;
  text-align: left;
}

.name {
  font-weight: bold;
  color: rgb(184, 37, 37);
}

.examples {
  display: grid;
  grid-template-columns: 0% auto 4% auto 0%;
}

.side {
  display: grid;
  grid-template-rows: 0% auto 5% auto 0%;
}

/* //Mobile// */
@media screen and (max-width: 600px) {
  .aboutScreenMobile {
    display: grid;
    grid-template-columns: 3% auto 5%;
  }

  .context {
    display: grid;
    grid-template-rows: 2% 3% 1% auto 25% auto 32% 5%;
    height: 100%;
  }

  .aboutHeader {
    display: grid;
    grid-template-columns: auto 2%;
  }

  .introAndContact {
    display: grid;
    grid-template-columns: auto 3% auto 5%;
  }

  .mainText {
    height: fit-content;
    display: grid;
    grid-template-rows: 20% 20% 20% 20%;
  }

  .tech {
    height: fit-content;
    display: grid;
    grid-template-rows: 20% 25% 25% 25%;
  }
}

/* responsiveness */
@media screen and (min-width: 2001px) {
  .About {
    font-size: 28px;
  }
  .title {
    font-size: 40px;
    font-weight: 500;
  }

  .example > .title {
    font-size: 35px;
  }

  .example > .title > img {
    width: 25%;
  }
}

@media screen and (min-width: 1301px) and (max-width: 2000px) {
  .About {
    font-size: 20px;
  }
  .title {
    font-size: 30px;
    font-weight: 400;
  }

  .example > .title {
    font-size: 25px;
  }

  .example > .title > img {
    width: 20%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1300px) {
  .About {
    font-size: 15px;
  }
  .title {
    font-size: 25px;
    font-weight: 400;
  }

  .example > .title {
    font-size: 20px;
  }

  .example > .title > img {
    width: 20%;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .About {
    font-size: 12px;
  }
  .title {
    font-size: 15px;
    font-weight: 400;
  }

  .example > .title {
    font-size: 14px;
  }

  .example > .title > img {
    width: 15%;
  }
}

@media screen and (max-width: 600px) {
  .About {
    font-size: 10px;
  }
  .title {
    font-size: 15px;
    font-weight: 400;
  }

  .example > .title {
    font-size: 12px;
  }

  .example > .title > img {
    width: 10%;
  }
}
