.Layout {
	height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 5% 25% auto;
}



.Layout > main {
    grid-column: span auto;
    overflow: auto;
}

.cardDiv {
    position :static;
}

/* .Layout > * {
    border: 1px solid black;
} */
